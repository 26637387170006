function filterTable(search, table) {
	$(table + " tr:hidden").show();
	$.each(search, function() {
		if (search != 'all') {
			$(table + " tr:visible .indexColumn:not(:contains('" + this + "'))").parent().hide();
		} else {
			$(table).show();
		}
	});
}
$(document).ready(function() { 	/*==========================================================	Accordion (Fill out a Quote)	==========================================================*/
	$(function() {
		$('.accordion').accordion({active: 0, collapsible: false, heightStyle: content});
	});				/*==========================================================	Tabs (Fill out a Quote) w/ Animation and Next/Previous buttons	==========================================================*/
	$(".tabs").tabs({active: false, collapsible: true, hide: {effect: "slide", duration: 500}, show: {effect: "slide", duration: 500}});
	$('.tabs').tabs("option", "active", 0).tabs("option", "collapsible", false).fadeIn('fast');
	$(".nexttab").click(function(e) {
		e.preventDefault();
		curTab = $(".tabs").tabs('option', 'active');
		numTabs = $(".tabs li").length;
		nextTab = curTab + 1;
		if (nextTab < numTabs) {
			$(".tabs").tabs("option", "active", nextTab);
		}
	});
	$(".previoustab").click(function(e) {
		e.preventDefault();
		curTab = $(".tabs").tabs('option', 'active');
		nextTab = curTab - 1;
		if (nextTab >= 0) {
			$(".tabs").tabs("option", "active", nextTab);
		}
	});
	$("table.filter-table tr:has(td)").each(function() {
		var t = $(this).text().toLowerCase();
		$("<td class='indexColumn'></td>").hide().text(t).appendTo(this);
	});
	$("#filter-select").change(function() {
		var str = "";
		$("#filter-status option:selected").each(function() {
			str += $(this).text() + " ";
		});
		var search_term = $(this).val().toLowerCase().split(" ");
		filterTable(search_term, ".filter-table");
	});

//this function is for the delete feature on the upload/index page

$('.delFile').click(function(event){
	if(confirm('Are you sure you would like to delete this file?\n\n!! WARNING !! This cannot be reversed!'))
	{}
	else {
		event.preventDefault();
		return false;
	}
});

//****************

	$('#responsive-menu-button').sidr({
    name: 'sidr-main',
    source: '#navigation'
  });



});
